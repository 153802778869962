.content {
    float:left;
    margin-top:100px;
    width:85%;
    position:relative;
    opacity:0;
    animation: fadein 1s .5s;
    animation-fill-mode: forwards;
    ul {
        margin:0 0 25px 25px;
        li {
            list-style-type:disc;
            span {
                font-style: italic;
                font-weight:400;
            }
        }
    }
    p {
        &:last-child {
            margin-bottom:0;
        }
    }
    .btn {
        margin-top:25px;
    }
}
