// Font / Type
@import url('https://fonts.googleapis.com/css?family=Merriweather:300,300i,400,400i');
$base__font-family: 'Merriweather', serif;
$base__font-size: 20px;
$base__font-weight: 400;

// Colours
$color__primary: #3B4C68;
$color__highlight: #81BCFF;

// Layout Controls
$nav__offset: 350px;
$global__padding: 80px;

// Breakpoints
$tablet: max-width 1024px;
$mobile: max-width 768px;