.container {
    width:calc(100% - #{$nav__offset});
    height:100vh;
    float:left;
    position:relative;
    background-image: url('dist/images/background.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &:before {
        content:'';
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background: linear-gradient(-45deg, lighten($color__primary, 10%), darken($color__primary, 10%));
        opacity:.95;
    }
    &__wrap {
        position:absolute;
        padding:$global__padding;
        width:100%;
        height:100vh;
        float:left;
        overflow-y: scroll;
        &::-webkit-scrollbar-track {
            background-color: $color__primary;
            height:100%;
        }

        &::-webkit-scrollbar {
            width: 5px;
            background-color: darken($color__primary, 10%);
        }

        &::-webkit-scrollbar-thumb {
            background-color: darken($color__primary, 10%);
        }

        &__logo {
            position:absolute;
            top:$global__padding;
            left:$global__padding;
            z-index:5;
        }
    }
}