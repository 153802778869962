body, html {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

// Page Transitions
.fade-enter, .fade-appear {
    opacity: 0;
    transition: opacity .75s;
    z-index:1;
}

.fade-leave {
    opacity: 1;
    transition: opacity .75s;
    z-index:5;
}

.fade-leave.fade-leave-active {
    opacity: 0;
}

.fade-appear.fade-appear-active, .fade-enter.fade-enter-active {
  opacity: 1;
}

// Animations
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes widthCont {
    from { opacity:0; width: 100%; }
    to  { opacity:1; width: calc(100% - 350px); }
}