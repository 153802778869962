@include breakpoint($tablet) {
    $nav__offset: 300px;
    $global__padding: 50px;

    .navigation {
        width: $nav__offset;
        padding: $global__padding $global__padding $global__padding 0;
        &__social {
            bottom: $global__padding;
            right: $global__padding;
        }

        nav {
            ul {
                li {
                    margin:0 0 10px;
                    a {
                        @include font-size(16px);
                        line-height:25px;
                    }
                }
            }
        }
    }

    .container {
        width: calc(100% - #{$nav__offset});
        &__wrap {
            padding: $global__padding;
            &__logo {
                top: $global__padding;
                left: $global__padding;
            }

            .content {
                li {
                    line-height: 32px;
                    ul {
                        margin:0 0 15px 25px;
                    }
                }
            }
        }
    }

}

@include breakpoint($mobile) {

    .navigation {
        display:none;
    }

    .container {
        width: 100%;
        &__wrap {
            .content {
                width: 100%;
            }
        }
    }
}