.btn {
    display:inline-block;
    color:#FFF;
    position:relative;
    z-index:0;
    line-height:50px;
    border-bottom:1px solid #FFF;
    font-style: italic;
    padding:0 10px;
    @include font-size(20px);
    &:before {
        content:'';
        width:0;
        height:1px;
        position:absolute;
        bottom:-1px;
        left:0;
        background:lighten($color__primary, 15%);
        @include transition(.4s);
    }
    &:hover {
        &:before {
            width:100%;
        }
    }
}