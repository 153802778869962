.navigation {
    width:$nav__offset;
    height:100%;
    position:absolute;
    top:0;
    right:0;
    padding:$global__padding $global__padding $global__padding 0;
    animation: fadein 1.5s;
    background:#FFF;
    nav {
        ul {
            li {
                float:right;
                width:100%;
                margin:0 0 15px;
                text-align:right;
                @include transition(.2s);
                a {
                    @include font-size(18px);
                    line-height:28px;
                    display:inline-block;
                    position:relative;
                    overflow:hidden;
                    font-weight:400;
                    &:before {
                        content:'';
                        position:absolute;
                        bottom:0px;
                        height:1px;
                        width:100%;
                        display:block;
                        background:$color__primary;
                        transform: translateX(100%);
                        @include transition(.2s);
                    }
                    &.active, &:hover {
                        &:before {
                            transform: translateX(0);
                        }
                    }
                    &.active {
                        pointer-events: none;
                    }
                }
            }
        }
    }

    &__social {
        position:absolute;
        bottom:$global__padding;
        right:$global__padding;
        &__item {
            float:right;
            width:35px;
            height:35px;
            margin-left:15px;
            img {
                width:100%;
            }
            &:first-child {
                float:right;
                width:100%;
                text-align:right;
                margin:0;
                margin-bottom:10px;
                a {
                    @include font-size(16px);
                }
            }
        }
    }
}
