@include breakpoint($tablet) {
    p {
        @include font-size(18px);
        line-height:38px;
        margin:0 0 15px;
    }

    ul {
        li {
            @include font-size(18px);
        }
    }

    h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5 {
        margin:0 0 15px;
        font-weight:400;
    }

    h1, .h1 {
        @include font-size(26px);
        line-height:42px;
    }

    h2, .h2 {
        @include font-size(24px);
    }

    h3, .h3 {
        @include font-size(22px);
    }

    h4, .h4 {
        @include font-size(20px);
    }

    h5, .h5 {
        @include font-size(18px);
    }
}