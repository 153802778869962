body {
    font-size: $base__font-size;
    font-family: $base__font-family;
    font-weight: $base__font-weight;
    color: #FFF;
    line-height: 40px;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
}

a {
    color:$color__primary;
    text-decoration:none;
    @include transition(.2s all);
}

ul {
    li {
        font-weight:300;
        @include font-size(20px);
    }
}

p {
    margin:0 0 25px;
    font-weight:300;
    a {
        font-style: italic;
        font-weight:400;
        color:#FFF;
        border-bottom:1px solid transparent;
        &:hover {
            border-bottom:1px solid #FFF;
        }
    }
    i, em {
        font-style: italic;
    }
}

h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5 {
    margin:0 0 25px;
    font-weight:400;
}

h1, .h1 {
    @include font-size(36px);
    line-height:56px;
}

h2, .h2 {
    @include font-size(34px);
}

h3, .h3 {
    @include font-size(32px);
}

h4, .h4 {
    @include font-size(28px);
}

h5, .h5 {
    @include font-size(24px);
}